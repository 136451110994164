import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ImpressumPage = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Impressum" description="" pathname={location.pathname} />
      <section className="section">
        <div className="container">
          <h1 className="has-text-centered">Impressum</h1>
          <div
            className="marked-content"
            dangerouslySetInnerHTML={{
              __html: data.cockpitImpressum.content.value,
            }}
          ></div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query Impressum {
    cockpitImpressum {
      content {
        value
      }
    }
  }
`

export default ImpressumPage
